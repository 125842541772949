@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  min-width: 250px
  border-radius: $small-border-radius

.titleContainer
  padding: 8px 16px
  display: flex
  flex-direction: column
  gap: 4px
  background-color: $primary50Color
  border-top-left-radius: $small-border-radius
  border-top-right-radius: $small-border-radius
  flex: 1 1 auto

.viewProduct
  display: flex
  align-items: center
  justify-content: space-between
  padding: 16px
  background: $secondary100Color
  border-bottom-left-radius: $small-border-radius
  border-bottom-right-radius: $small-border-radius

.root.variant-recommended
  border: 1px solid $gold100Color
