
.root
  display: flex
  flex-direction: column
  align-items: stretch
  gap: 16px
  flex: 1
  margin-block: 30px 60px

.body
  display: flex
  flex-direction: column
  justify-content: center
  align-items: stretch
  flex: 1 1 418px
  max-width: 418px
  gap: 24px
  margin-inline: auto

  .copy
    display: flex
    flex-direction: column
    gap: 8px
    margin-block: 20px

.header
  display: grid
  grid-template-columns: 1fr
  align-items: center
  gap: 32px
  margin-bottom: 24px
