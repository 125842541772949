
@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: center
  gap: 32px
  margin-block: 30px 60px
  width: 100%
  flex: 1

.body
  display: flex
  flex-direction: column
  justify-content: center
  gap: 24px
  width: 100%
  text-align: center
  max-width: 800px
  flex: 1
  margin-inline: auto

  .inputContainer
    display: flex
    flex-direction: column
    gap: 4px
    width: 100%

  .input
    flex-direction: column
    padding-bottom: 18px
    height: 270px

  .fileCard
    margin-left: 10px

.header
  display: grid
  grid-template-columns: 150px auto 150px
  align-items: center
  gap: 32px
  margin-bottom: 24px
