.centeredTableRow
	display: flex
	justify-content: center
	align-items: center

.userCard
	margin-bottom: 8px
	h4
		margin-bottom: 0

.ratingWidget
	font-size: 16px
