@import 'src/theme/constants.scss'

$sidebarWidth: 70px

.root
  position: fixed
  bottom: 58%
  left: $sidebarWidth
  right: 0
  width: calc(100vw - $sidebarWidth - 1rem)
  transition: transform, bottom .7s ease-out

  .centerText
    display: flex
    text-align: center
    position: absolute
    padding: 0px 20px
    left: 0
    right: 0
    margin: auto
    top: 0
    width: 100%
    justify-content: center
    flex-direction: column
    align-items: center
    gap: 8px
    pointer-events: none
    &>*
      pointer-events: all

  .header
    display: flex
    text-align: center
    flex-direction: column
    position: absolute
    bottom: calc(100% + 16px)
    gap: 16px

  .footer
    position: absolute
    top: calc(100% + 16px)
    width: 100%

.root.down
  bottom: 12px

  .centerText
    transform: translateY(-100%)
    width: 100%
    max-width: 1500px
    align-items: flex-start

  .moveLeft
    align-items: flex-start

@media (max-width: $tablet-max-height)

@media (max-width: $tablet-max-height)
  .centerText
    padding-right: 16px
  .root
    width: 100vw
    left: 0%

  .header
    width: calc(100vw - 140px)
    p
      text-wrap: balance
  .footer
    width: calc(100% - 40px)
    display: flex
    justify-content: center

@media (max-width: $mobile-min-width)
  .header
    width: calc(100vw - 40px)
    .centerText
      top: 32px !important

@media (min-width: $tablet-max-height)
  .footer
    width: calc(100vw - 100px) !important

@media (max-height: $mobile-min-width)
  .root
    bottom: 65%

@media (max-width: $tablet-max-width)
  .root
    .centerText
      width: 100%
    .moveLeft
      align-items: center !important
      padding-right: 4px

@media (max-width: $tablet-max-height)
  .root
    .centerText
      padding-left: 12px
      padding-right: 12px

@media (max-width: $tablet-min-height)
  .root
    .centerText
      padding-right: 12px

@media (min-width: $tablet-max-width)
  .root
    transition: none
    .centerText
      padding-right: 30px
@media (min-width: $container-max-width)
  .root
    .centerText
      padding-left: 30px

@media (min-width: $container-max-width)
  .root
    .down
      width: calc(65% - 4px)
    .centerText
      padding-right: 0px
      padding-left: 70px
