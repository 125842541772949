@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'

.root
  width: 800px

.inputsWrapper
  display: flex
  flex-direction: column
  align-items: stretch
  gap: 20px
  flex: 1
  &>div 
    display: flex
    gap: 8px
    flex-direction: column

.form
  display: grid
  grid-template-columns: 1fr
  width: 100%
  margin: 0 auto
  gap: 20px

.titleContainer
  display: flex
  padding-bottom: 24px
  justify-content: center
  flex-direction: column
  align-items: center
  gap: 8px
  border-bottom: 1px solid $mainText50Color


.footer
  height: auto
  display: flex
  justify-content: center
  gap: 16px
  margin-top: 32px 
  width: 100%

.selectedFile
  display: flex
  width: 100%
  padding: 8px
  border-radius: 8px
  gap: 8px
  background-color: $primaryContrast10OpacityColor
  align-items: center
  img
      height: 28px
      width: 28px

.fileList
  max-height: 200px
  overflow-y: auto

.documentDropZone
  background: $primary70_20Color
  height: auto
  border-color: $primary80Color

.dropZoneHeader
  display: flex
  gap: 8px
  font-size: 16px
  i
    font-size: 24px

.fileInfo
  font-size: 12px
  padding-left: 4px
  margin-top: 4px
  display: flex
  justify-content: space-between

@media (max-width: $tablet-min-height)
  .footer
    button
      flex: 1