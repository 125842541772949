@import "@root/theme/colors.scss"

.root
  display: flex
  user-select: none
  outline: none
  border: 1px solid #6b6b6b45
  min-height: 100%
  max-height: 100%
  min-width: 100%
  overflow: hidden

  .content
    flex: 1
    overflow: hidden
    text-overflow: ellipsis
    padding: 6px
    white-space: pre-wrap

  .arrow
    display: flex
    padding: 3px 3px
    align-self: stretch
    cursor: pointer
    background: transparent
    color: $mainText70Color
    border: none
    outline: none
    text-align: start

    &>*
      padding: 2px
      border-radius: 4px
      background: $primaryCardL3BGColor

    &:hover
      color: $mainText100Color

  &.withError
    border: 1px solid $red80Color

  .status
    position: absolute
    bottom: 1px
    right: 1px
    display: flex
    background: $primaryCardL3BGColor
    color: $primaryContrast100Color
    border-top-left-radius: 12px
    font-size: 18px
    &>*
      padding: 3px
      border-radius: 4px
    .error
      color: $red100Color
    .info
      color: $mainText70Color
    .notification
      color: $gold100Color

.eventTransparent
  pointer-events: none

.v-align-start
  align-items: flex-start
.v-align-center
  align-items: center
.v-align-end
  align-items: flex-end

.h-align-start
  text-align: start
.h-align-center
  text-align: center
.h-align-end
  text-align: end

.text-bold .content
  font-weight: bold
.text-italic .content
  font-style: italic
.text-strike .content
  text-decoration: line-through

.text-normal .content
  font-size: 1em
.text-h3 .content
  font-size: 16px
.text-h2 .content
  font-size: 20px
.text-h1 .content
  font-size: 24px

.underlined
  text-decoration: underline !important
