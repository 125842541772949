.root
  display: grid
  grid-template-rows: auto auto 1fr
  width: 100%
  align-items: flex-start

.backButton
  font-size: 22px

.header
  margin-bottom: 20px

.searchInput
  min-width: 300px

.header
  align-items: center

