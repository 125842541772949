@import 'src/theme/constants.scss'

.root
  padding: 20px 24px
  border-radius: 16px
  background-color: $primaryCardBGColor
  height: fit-content

.header
  margin-bottom: 12px

  .icon
    font-size: 32px
    margin-bottom: 8px

.list
  padding-left: 24px
  margin-block: 8px

.item
  margin-block: 8px
  list-style-type: square
  &::marker
    color: $primary100Color

.footer
  margin-top: 12px
