@import './colors.scss'

@mixin thinScroll($selector: '*', $width: 5px)
  #{$selector}::-webkit-scrollbar
    width: calc($width * 2.5)
    height: calc($width * 2.5)

  #{$selector}::-webkit-scrollbar-track,
  #{$selector}::-webkit-scrollbar-corner
    background-color: transparent

  #{$selector}::-webkit-scrollbar-thumb
    background: $secondaryScrollColor
    border-radius: $width
    border: calc($width) solid transparent
    background-clip: content-box
