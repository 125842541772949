@import 'src/theme/constants.scss'

.loadingMessages
  display: flex
  flex-direction: column
  justify-content: flex-start
  width: fit-content
  gap: 24px
  position: relative
  top: 25vh

.loading
  display: flex
  gap: 8px
  align-items: center

.neshIcon
  background: $primary100Color
  border-radius: 4px

.loader
  font-size: 14px
  background: linear-gradient(90deg, $mainText100Color 0%, #B963FF 100%)
  background-clip: text
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  white-space: nowrap
  width: 500px
  text-overflow: ellipsis
  overflow: hidden