.big
    font-size: 22px
    --file-card-padding: 8px
.medium
    font-size: 18px
    --file-card-padding: 8px
.small
    font-size: 14px
    --file-card-padding: 4px 8px
    --file-card-icon-size: 12px
    --file-card-icon-padding: 6px
