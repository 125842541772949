@import 'src/theme/colors.scss'

.root
  display: flex
  justify-content: space-between
  padding: 8px
  border-radius: 6px
  gap: 8px
  align-items: center
  background-color: $primaryContrast10OpacityColor
  cursor: pointer
  border: 1px solid transparent
  &:hover
    border: 1px solid $primary80Color
    background: $primary80_10Color
  span
    flex: 1
    font-size: 12px
  i
    font-size: 16px

.disabled
  opacity: 0.7
  cursor: default
