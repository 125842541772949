@import "src/theme/colors.scss"

.root
  width: 100%

.tooltipContainer
  width: 100%

.menuContainer
  padding: 8px
  margin: 0

.menu
  max-height: 220px
  overflow: overlay

.menu::-webkit-scrollbar
  display: none

.after
  display: flex
  gap: 10px
  margin-right: 10px

.divider
  display: block
  height: 1px
  max-width: 100%
  background-color: $text15Color
  margin-inline: 58px 20px
  margin-block: 4px
  margin-inline: 0

.valueItem
  display: flex
  align-items: center
  flex: 1 1 100%
  padding: 8px
  justify-content: space-between
  cursor: pointer

.disabled
  pointer-events: none
  opacity: .7

.noResults
  padding: 8px
  color: $mainText50Color
