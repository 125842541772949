@font-face {
  font-family: "icomoon";
  src: url("./icomoon.eot?db52lj");
  src: url("./icomoon.eot?db52lj#iefix") format("embedded-opentype"),
    url("./icomoon.ttf?db52lj") format("truetype"),
    url("./icomoon.woff?db52lj") format("woff"),
    url("./icomoon.svg?db52lj#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
