@import 'src/theme/colors.scss'

$containerPadding: var(--container-padding, 3px)
$height: var(--container-height, 42px)
$itemsPadding: var(--button-padding, 20px)

$main-bg: $inputMainBgColor
$border-color: transparent
$border-width: 1px
$checked-text-color: $primary10Color
$checked-item-color: $inputMainColor

.root
  --container-padding: 3px
  --container-height: 42px
  --button-padding: 20px
  display: flex
  justify-content: center
  position: relative
  height: fit-content
  overflow: hidden
  font-size: 14px
  margin: 0
  padding: 0
  border: none
  min-height: 32px
  min-inline-size: auto

.root:disabled
  opacity: 0.2
  cursor: default
  pointer-events: none

.container
  display: flex
  overflow-x: auto
  position: relative
  border-radius: inherit
  height: fit-content
  max-width: 100%
  width: fit-content
  &::-webkit-scrollbar
    display: none

.item
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  position: relative
  min-height: calc(#{$height} - #{$containerPadding} * 2 - #{$border-width} * 2)
  background-color: transparent
  color: $mainText40Color
  flex: 1 1 50%
  padding: 0 $itemsPadding
  transition: color var(--transition-speed, 0s), background-color 0s
  min-width: fit-content
  border: none
  outline: none
  user-select: none
  cursor: pointer
  font-weight: 600

.item:disabled
  cursor: default

.checked
  color: $mainText100Color
  border-radius: inherit

.size-small
  --container-height: 30px
  --button-padding: 12px
  --container-padding: 2px
  font-size: 12px

.size-medium
  --container-height: 36px
  --button-padding: 16px
  --container-padding: 2px
  font-size: 14px

.size-big
  --container-height: 42px
  --button-padding: 20px
  --container-padding: 3px
  font-size: 14px

.number
  width: 20px
  height: 20px
  border-radius: 50%
  background-color: $primary50Color
  margin-right: 8px
  vertical-align: middle
  line-height: 20px
  display: flex
  align-items: center
  justify-content: center

.active
  width: 20px
  height: 20px
  border-radius: 50%
  background-color: $primary100Color

.currentActive
  width: 28px
  height: 28px
  border-radius: 50%
  border: 1px solid $mainText100Color
