@import "@root/theme/colors.scss"

$textShades: '100' , '80' , '70' , '50' , '40'

@each $color in $textShades
  .text#{$color}Color
    color: var(#{'--main-text-#{$color}-color'})

$otherColors: 'green' , 'red' , 'gold'

@each $color in $otherColors
  .#{$color}Color
    color: var(#{'--#{$color}-100-color'})

.textInheritColor
  color: inherit
