@import 'src/theme/colors.scss'

.root
  max-width: 100%
  padding: 32px
  border-radius: 8px
  width: 600px

.container
  display: flex
  flex-direction: column
  align-items: stretch

.body
  display: flex
  flex-direction: column
  margin-bottom: 20px

.label
  margin-bottom: 8px
  font-size: 12px

.footer
  display: flex
  justify-content: space-between
  margin-top: 20px
  button
    width: 230px

.input
  flex: 1 1 100%
  border: 1px solid $primaryContrast100Color
  height: 200px
  border-radius: 6px
  textarea
    height: 200px
  