@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  gap: 16px
  min-width: 269px
  background-color: #FFFFFF14
  border-radius: $small-border-radius
  padding: 16px
  height: fit-content

  .button
    background-color: #FFFFFF1A
    border-radius: $small-border-radius
    justify-content: space-between
    padding: 8px
    height: fit-content
    font-size: 12px
