@import 'src/theme/constants.scss'

.input
  display: flex
  flex: 1 1 100%
  color: $mainText70Color

  i
    font-size: 1.3em

  input
    border: none
    background: transparent
    outline: none
    color: $mainText100Color
    flex: 1 1 100%

  input::placeholder
    color: $mainText50Color

.filtersWrapper
  display: flex
  flex-direction: column
  gap: 16px
  width: 200px
  padding-right: 12px
  border-right: 1px solid $mainText10Color
  cursor: pointer

.filtersInfo,
.filters
  display: flex
  flex-wrap: nowrap
  color: $mainText100Color
  h6
    color: $mainText70Color
    margin-right: 4px

.filtersInfo
  align-items: flex-start

.filters
  align-items: center
  gap: 8px

.filterCountBadge
  display: none
  position: absolute
  height: 16px
  width: 16px
  padding: 0px
  font-size: 12px
  min-height: 10px
  min-width: 10px
  left: 12px
  top: 6px
  span
    margin: 0px !important

@media (max-width: $mobile-min-width)
  .filtersWrapper
    width: auto
    padding-right: 8px
  .filtersInfo
    display: none
  .input
    input
      width: 100%
  .filters
    p
      display: none
  .filterCountBadge
    display: block

@media (max-width: $tablet-max-height)
  .filtersWrapper
    padding-right: 8px

.inputField
  padding-inline: 12px
