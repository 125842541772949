@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  width: 100%
  max-width: $mobile-min-width
  align-items: center

.logo
  margin-bottom: 42px

.header
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  gap: 10px

.body
  display: flex
  flex-direction: column
  gap: 24px
  width: 100%
  padding: 36px 0

.footer
  max-width: 350px

.divider
  display: flex
  align-items: center
  width: 100%
  color: $mainText50Color
  margin-block: 12px

  &::before,
  &::after
    content: ''
    flex: 1
    height: 1px
    background: $mainText20Color
    margin-inline: 10px

  @media (max-width: $tablet-max-width)
    margin-block: 6px
