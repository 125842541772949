@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  align-items: stretch
  width: 100%
  color: $mainText70Color

  h3
    margin-bottom: 8px

  h4
    margin-bottom: 8px

  h5
    margin-bottom: 4px

  a:hover
    text-decoration: underline

  a, a:visited
    color: inherit
    text-decoration: none

.header
  padding-inline: 16px
  margin-bottom: 20px

  .title
    margin-bottom: 12px

.body
  display: flex
  flex: 1 1 100%
  border-top: 1px solid $dividerLineLightColor

.menu
  flex: 1 1 30%
  min-width: 300px
  padding: 16px
  border-right: 1px solid $dividerLineLightColor
  background: $primaryCardL2BGColor
  position: sticky
  top: 8px
  height: 86vh

  .title
    margin-bottom: 12px

  .menuItem
    padding-block: 8px

.content
  flex: 1 1 auto
  padding-inline: 32px

.section
  padding-block: 20px

.section:not(:first-child)
  border-top: 1px solid $dividerLineLightColor

.block:not(:last-child)
  margin-bottom: 20px

.textBlock
  margin-block: 4px 8px
  h4, h3
    margin-top: 8px
    margin-bottom: 4px
    font-weight: bold
    color: $mainText100Color
  p
    margin-bottom: 8px
  ol
    margin-bottom: 16px
    li
      margin-bottom: 6px
  ul
    list-style-type: disc
    width: 100%
    margin-bottom: 16px
    li
      width: 100%
      align-items: center
      flex-wrap: wrap
      justify-content:left
      margin-bottom: 6px

.table
  padding-inline: 20px
  margin-bottom: 16px

.list
  margin-left: 20px

.subsection:not(:last-child)
  margin-bottom: 24px

@media (max-width: $tablet-max-height)
  .body
    flex-direction: column

  .content
    padding-inline: 16px

  .header
    margin-block: 16px
    
  .menu
    position: static
