
.root

  .header
    color: #FFFFFF

  .row
    padding: 24px 0

  .cell
    padding-top: 24px
    padding-bottom: 24px