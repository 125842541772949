@import 'src/theme/constants.scss'

.root
  display: grid
  grid-template-rows: auto auto 1fr
  width: 100%
  align-items: flex-start

.header
  margin-bottom: 20px

.searchInput
  min-width: 300px

.grid
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr))
  gap: 20px

.listItem
  padding: 18px 20px

.listItem:hover
  border: 1px solid $primary100Color
