@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'

.root
  width: 800px

.inputsWrapper
  display: flex
  flex-direction: column
  align-items: stretch
  gap: 20px
  flex: 1
  &>div 
    display: flex
    gap: 8px
    flex-direction: column

.form
  display: grid
  grid-template-columns: 1fr
  width: 100%
  margin: 0 auto
  gap: 20px
  max-height: 700px

.titleContainer
  display: flex
  padding-bottom: 24px
  justify-content: center
  flex-direction: column
  align-items: center
  gap: 8px
  border-bottom: 1px solid $mainText50Color


.footer
  height: auto
  display: flex
  justify-content: center
  gap: 16px
  margin-top: 32px 
  width: 100%

.inputContainer
  fieldset
    width: 300px

.error
  margin-right: 8px
  span
    display: flex

@media (max-width: $tablet-min-height)
  .footer
    button
      flex: 1