
@import 'src/theme/colors.scss'
@import '@root/theme/mixins.sass'
@import 'src/theme/constants.scss'

.root
  height: 100%
  width: 100%
  padding: 24px 16px
  display: flex
  flex-direction: column
  gap: 16px
  transition: opacity 0.5s ease-in-out
  opacity: 0
  overflow-y: auto
  @include thinScroll('&', 0px)

.visible
  opacity: 1

.lineItemHeading
  display: flex
  flex-direction: column
  max-width: 100%
  text-overflow: ellipsis
  overflow: hidden
  gap: 8px

.noWrap
  max-width: 100%
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.lineItemHeading,
.lineItemOption
  width: 100%
  height: auto
  justify-content: space-between
  color: $mainText100Color

.insideCollection
  display: flex
  padding: 8px
  flex-direction: column
  gap: 8px
  overflow: hidden

.lineItemOption
  background-color: $primaryContrast10OpacityColor
  border-radius: 8px
  padding: 10px 8px
  font-size: 12px
  border: 1px solid transparent
  i
    font-size: 16px
  &:hover
    background: $primary80_10Color
    border-color: $primary80Color

.lineItem
  display: flex
  flex-direction: column
  border-radius: 8px
  border: 1px solid $primary70_20Color

.lineItemWrapper
  display: flex
  flex-direction: column
  gap: 8px

.skeletonLoader
  margin-bottom: 8px

@media(max-width: $tablet-max-width), (max-width: $tablet-min-height)
  .root
    width: 100%
    overflow: auto
    padding: 0px
    border: none
    background: transparent
    border-top: 0.5px solid $dividerLineLightColor
    border-radius: 0px
    padding-top: 16px

  .lineItemWrapper
    min-height: 200px

@media(max-width: $tablet-max-width)
  .root
    flex-direction: row

  .lineItemWrapper
    width: 300px
    max-width: 300px

  .skeleton
    display: flex
    gap: 8px
    width: 100%

@media(max-width: $tablet-min-height)
  .root
    width: 100%
    overflow-x: auto
    flex-direction: row
    @include thinScroll('&', 2px)

  .lineItemWrapper
    width: 100%
    max-width: 100%
