@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  width: 100%
  max-width: 1500px

.sidePanel
  width: 35%
  height: 100%
  padding: 0 24px 0 24px

.container
  height: 100%
  width: 100%
  max-width: 900px
  overflow: auto
  flex: 1
  position: relative
  @include thinScroll('&', 0px)

.body
  display: flex
  flex-direction: column
  align-items: center
  padding-bottom: 220px
  height: fit-content
  width: 100%
  &::-webkit-scrollbar
    width: 2px
  &::-webkit-scrollbar-thumb
    background-color: $primaryContrast10OpacityColor

@media(max-width: $tablet-max-width)
  .sidePanel
    width: 100%
    padding: 20px
  .moveRight
    width: 400px

@media(min-width: $container-max-width)
  .root
    justify-content: center
  .container
    max-width: 1000px
  .sidePanel
    max-width: 400px

@media(max-width: $container-max-width)
  .container
    max-width: 1500px
