@import 'src/theme/constants.scss'
.root
  display: flex
  flex-direction: column
  align-items: stretch
  margin-block: 8px
  gap: 24px

.header
  display: flex
  align-items: stretch
  gap: 16px

  .question
    display: flex
    flex-direction: column
    justify-content: center

.body
  display: flex
  position: relative
  flex-direction: column
  align-items: stretch
  overflow: auto
  flex: 1 1 auto
