@import 'src/theme/constants.scss'

.star
  font-size: 24px

.tooltip
  background: $primaryCardBGColor
  color: $mainText100Color
  padding: 8px
  min-width: 300px
  display: flex
  flex-direction: column
  gap: 8px

.defaultRating
  display: flex
  gap: 4px
