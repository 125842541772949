@import 'src/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  overflow: hidden
  gap: 16px

.body
  overflow: hidden
  @include thinScroll('*')
  &>*
    min-height: min(300px, 100%)
    max-height: 100%

.footer
  display: flex
  justify-content: space-between
  align-items: center
  gap: 16px

.footerText
  display: flex
  gap: 8px

.infoIcon
  margin-right: 5px

.buttonContainer
  display: flex
  gap: 8px
  &>*
    min-width: 100px
