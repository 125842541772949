@import 'src/theme/colors.scss'

.root
  position: relative
  display: flex
  align-items: stretch
  width: 100%
  max-width: 100%
  height: 51px
  border-radius: 6px
  background: $text10Color
  gap: 10px
  user-select: none
  h4
    text-align: center
    color: $mainText100Color
    line-height: 17px
    margin: 0

.container
  display: flex
  align-items: center
  flex: 1 1
  padding: 12px 16px
  gap: 16px
  max-width: 100%

.titleContainer
  width: 100%

.title
  text-align: start
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  width: 100%

.nameTooltip
  font-size: 12px
  padding: 7px 10px
  width: max-content
  max-width: 250px
  white-space: pre-wrap

.icon
  display: flex
  align-items: center

.cancelBtn
  font-size: 12px
  width: 25px
  height: 25px
  border-radius: 50%
  background: $text10Color

.progress
  display: flex
  flex: 1 1
  align-items: flex-end
  overflow: hidden
