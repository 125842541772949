@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column

.otherSection
  padding-block: 12px

.cardsContainer
  display: flex
  flex-direction: column
  min-height: 260px

.productGrid
  display: grid
  gap: 24px
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))
