@import 'src/theme/constants.scss'

.root
  backdrop-filter: blur(10px)
  background: $widgetBgColor
  box-shadow: $highlightL10Shadow
  border: 1px solid $primary80Color
  border-radius: $big-border-radius
  max-width: min(900px, calc(100vw - 100px))
  width: 100%
  padding: 16px
  pointer-events: auto
  margin: 16px 0px

  &[aria-disabled="true"]
    cursor: not-allowed
    pointer-events: none
    user-select: none

.searchContainer
  display: flex
  flex-wrap: nowrap
  gap: 8px
  align-items: center

.input
  display: flex
  flex: 1 1 100%
  color: $mainText70Color
  padding: 8px
  gap: 8px

  i
    font-size: 1.3em

  input
    border: none
    background: transparent
    outline: none
    color: $mainText100Color
    flex: 1 1 100%

  input::placeholder
    color: $mainText50Color

.searchButton
  flex: 1 0 auto

@media (max-width: $tablet-max-height)
  .root
    max-width: calc(100vw - 32px)

@media (max-width: $mobile-min-width)
  .root
    padding: 8px
    border-radius: 8px

@media (max-height: $mobile-min-width)
  .root
    margin: 0px
    max-width: 700px

@media(min-width: $tablet-max-width)
  .down
    width: 66.5%
    max-width: 66.5%

@media (min-width: $desktop-min-height)
  .down
    width: 66%
    max-width: 66%

@media(min-width: $container-max-width)
  .root
    max-width: 980px
    margin-left: 0px
  .down
    width: 980px
    max-width: max(65%, calc(100% - 400px))
