
.root
  thead th
    width: 15%

.header
  display: flex
  align-items: center
  gap: 8px
  text-wrap: nowrap

.crossButton
  cursor: pointer
  font-size: 13px

.properties
  min-width: 200px
