@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  align-items: stretch
  gap: 32px
  width: 100%
  margin-block: 30px 60px

.summaryContainer
  display: flex
  flex-direction: column
  gap: 16px
  padding: 16px
  border-radius: $small-border-radius
  background: $inputMainBgColor

  .horizontal
    opacity: 0.08
    width: 100%

  .editButton
    align-self: flex-start

.textArea
  font-size: 14px

.headingContainer
  display: flex
  justify-content: space-between
  width: 100%
  align-items: center

.header
  display: grid
  grid-template-columns: 150px auto 150px
  align-items: center
  gap: 32px
  margin-bottom: 24px
