@import 'src/theme/colors.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  padding: 50px
  width: 700px
  max-width: 700px

.content
  margin-bottom: 40px
  border-radius: 16px
  padding: 8px 0px
  overflow: hidden
  min-height: 300px
  background: $primaryContrast10OpacityColor

.list
  display: flex
  flex-direction: column
  overflow-y: auto
  padding: 0px 8px
  max-height: 400px
  gap: 8px

.footer
  display: flex
  justify-content: center
  gap: 8px
  button
    width: 150px

.user
  display: flex
  gap: 8px
  padding: 8px
  border-radius: 8px
  cursor: pointer
  border: 1px solid transparent
  align-items: center
  background: $primary80_10Color
  i
    font-size: 15px
    background: $secondary50Color
    height: 32px
    width: 32px
    border-radius: 21px
  div
    display: flex
    flex-direction: column

.selected
  border: 1px solid $primary100Color

.selectedUsersContainer
  display: flex
  flex-direction: column
  gap: 8px
  margin-bottom: 16px

.selectedUsers
  max-width: 100%
  width: 100%
  background: $primaryContrast10OpacityColor
  min-height: 42px
  max-height: 48px
  padding: 8px 16px
  border-radius: 32px
  overflow-x: auto
  overflow-y: hidden
  display: flex
  gap: 8px
  scrollbar-width: none
  -ms-overflow-style: none
  &::-webkit-scrollbar
    display: none

  div
    display: flex
    width: max-content
    gap: 8px
    button
      background: $primaryContrast10OpacityColor
      padding: 4px 8px
      width: max-content
      height: max-content
      font-size: 12px
      i
        font-size: 10px
      > span:first-of-type > i
        height: 20px
        width: 20px
        border-radius: 12px

.header
  font-weight: 700 !important

.searchWrapper
  margin-bottom: 16px

.loader
  align-self: center

.selectedUsersHeader
  display: flex
  justify-content: space-between
  align-items: flex-end
  margin-bottom: 8px

.disabled
  opacity: 0.7
