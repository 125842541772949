@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  align-items: stretch
  width: 100%
  gap: 32px
  margin-block: 30px 60px

.productsContainer
  width: 100%
  background: $primaryCardL3BGColor
  border-radius: $big-border-radius
  padding: 24px
  display: flex
  flex-direction: column
  gap: 16px

.body
  display: flex
  flex-direction: column

.divider
  height: 1px
  width: 100%
  background: $dividerLineLightColor
  margin-block: 24px 16px

.tableContainer
  width: 100%

.horizontalScroll
  overflow-x: scroll

.filterInput
  min-width: 300px

.header
  display: grid
  grid-template-columns: 150px auto 150px
  align-items: center
  gap: 32px
  margin-bottom: 24px
