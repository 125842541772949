@import "src/theme/colors.scss"

.multiSelect
  position: relative
  width: 100%

  &.open .arrow
    transform: rotate(180deg)

.selectBox
  display: flex
  align-items: center
  justify-content: space-between
  padding: 10px
  cursor: pointer
  border: 1px solid $primaryContrast100Color
  &:hover
    background-color: $secondary80Color 

.selectedItems
  display: flex
  flex-wrap: wrap
  gap: 5px

.selectedItem
  display: flex
  background: transparent
  border: 0px
  span
    display: flex
    justify-content: center
    gap: 8px
    align-items: center
  i
    font-size: 12px

.plusIcon
  font-size: 12px
  margin-right: 8px


.removeItem
  cursor: pointer
  margin-left: 12px

.placeholder
  color: $text40Color
  margin-left: 4px
  padding-top: 4px
  padding-bottom: 4px

.arrow
  transition: transform 0.3s
  
.after
  display: flex
  align-items: center
  gap: 8px

.dropdownMenu
  position: absolute
  top: 100%
  left: 0
  width: 100%
  background-color: $inputMainBgColor
  border: 1px solid $text10Color
  border-radius: 8px
  margin-top: 5px
  z-index: 1000
  max-height: 200px
  overflow-y: auto
  padding-top: 8px
  &::-webkit-scrollbar
    width: 2px

.option
  padding: 10px
  cursor: pointer
  display: flex
  justify-content: space-between
  margin-left: 16px
  margin-right: 16px
  margin-top: 8px

.option:hover
  background-color: $primary80_15Color
    
.addNewContainer:hover
.selected:hover
  background-color: $secondary100Color
 
.selected
  background-color: $primary20Color

.addNewContainer
  display: flex
  width: 100%
  justify-content: center
  border-top: 1px solid $mainText50Color
  margin-top: 16px
  button
    height: 100%
    width: 100%
    border-radius: 0px
    padding: 10px
  button:hover
    background-color: $secondary100Color
    color: white

.option
  border-bottom: 1px solid $mainText50Color

.addNewInputContainer
  display: flex
  padding: 16px
  width: 100%
  input
    flex: 1
  button
    width: 64px
  .barLoader
    width: 128px

.paddingBottom
  padding-bottom: 16px

.rounded
  border-radius: 50vh

.secondary
  background-color: $inputMainBgColor
  border: 0px
  &:hover
    background-color: $inputMainBgColor 

.error
  border: 1px solid $red100Color
