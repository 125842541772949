@import 'src/theme/colors.scss'

.root
  display: grid
  grid-template-columns: 50px 1fr auto
  grid-template-rows: 1fr
  gap: 10px
  padding: 20px
  border-radius: 6px
  background: $primaryCardBGColor
  color: $mainText100Color
  align-items: center
  font-size: 14px

.value
  font-style: normal
  font-weight: 500
  font-size: 22px
  line-height: 27px
  margin: 10px
  text-align: end
