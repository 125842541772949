@import 'src/theme/colors.scss'

.root
    display: flex
    align-items: center
    justify-content: space-between
    gap: 24px
    width: fit-content
    background-color: $primary80_10Color
    border-radius: 4px
    padding: var(--file-card-padding, 8px)

    .remove
        cursor: pointer
        font-size: var(--file-card-icon-size, 23px)
        padding: var(--file-card-icon-padding, 11px)

    .disabled
        pointer-events: none
