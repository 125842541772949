@import 'src/theme/constants.scss'


.voiceInputBtn, .searchButton
  flex: 1 0 auto
  height: 60px
  width: 60px
  border-radius: 16px
  font-size: 18px

.voiceInputBtn
  background: $secondary80Color 
  background: $primaryContrast80Color
  color: $text100Color
  &:hover
    color: $text80Color

@media (max-width: $mobile-min-width)
  .voiceInputBtn, .searchButton
    height: 24px
    width: 24px
    border-radius: 4px
    padding: 0px
    min-height: 24px
    min-width: 24px
    font-size: 12px

