@import 'src/theme/constants.scss'
@import 'src/theme/colors.scss'
@import 'src/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 20px

.container
  @include thinScroll('&')
  flex: 1 1 auto
  width: 100%

.row
  color: $mainText100Color

  .boxMargin
    margin: auto
    margin-top: 10px

  .fullHeight
    height: 100%

.checkboxLabel
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  gap: 5px

.control
  width: 100%
  min-height: 50px
  display: flex
  justify-content: flex-end
  gap: 16px

.label
  width: 100%
  display: flex

.tooltip
  padding: 10px
  font-size: 1.5em
  cursor: pointer
  margin-block: -15px
  color: $mainText20Color

.tooltipPopup
  max-width: 300px

@media (max-width: $mobile-min-width)
  .control > *
    flex: 1 1 100%
