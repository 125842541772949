@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: center
  gap: 32px
  margin-block: 30px 60px
  width: 100%

.body
  display: flex
  flex-direction: column
  gap: 24px
  width: 100%
  min-height: 500px

  .listContainer
    width: 100%
    border-radius: 24px
    overflow: hidden
    flex: 1

    .themesList
      display: flex
      flex-direction: column
      flex-wrap: wrap
      gap: 24px
      width: 100%
      max-height: 1000px

.theme
  padding: 32px
  border-radius: 16px
  background-color: $primaryCardBGColor
  height: fit-content
  max-width: 330px

.content
  padding-top: 8px
  display: flex
  gap: 8px
  color: $primary100Color

  &:before
    content: "-"
    margin-top: 2px

.header
  display: grid
  grid-template-columns: 150px auto 150px
  align-items: center
  gap: 32px
  margin-bottom: 24px
