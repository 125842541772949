@import 'src/theme/constants.scss'

.root
  padding: 16px 60px
  display: flex
  gap: 50px
  height: calc(100vh - 146px)

  *::-webkit-scrollbar
    width: 4px
  
  *::-webkit-scrollbar-thumb
    background-color: $primary100Color
    border-radius: 0
  
  .leftContainer
    display: flex
    flex-direction: column
    gap: 24px
    overflow-y: scroll

    .titleContainer
      display: flex
      align-items: center
      justify-content: space-between
      padding-right: 8px

  .summaryContainer, .technicalSpecsContainer
    display: flex
    flex-direction: column
    gap: 16px

  .tableContainer
    

  .learnAbout
    width: fit-content
