@import 'src/theme/colors.scss'

.root
  h3
    margin-bottom: 5px
  p
    color: $mainText50Color

.list
  display: flex
  flex-wrap: wrap
  gap: 10px
  margin-top: 14px
