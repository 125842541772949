@import 'src/theme/constants.scss'

.root
  width: 100%
  color: $mainText70Color
  padding: 32px 60px
  height: 100vh

.box
  display: flex
  flex-direction: column
  border-radius: 24px
  background: $primaryCardBGColor
  height: fit-content
  min-height: calc(100vh - 64px)
  height: 100%

.hiddenDiv
  position: absolute,
  z-index: -1,
  top: -10000px,
  left: -10000px,
  width: 1080px,
  height: 720px,
  overflow-y: auto,

.header
  padding: 16px 32px
  border-bottom: 1px solid $dividerLineLightColor

.body
  display: flex
  padding: 24px 32px
  flex: 1 1 100%
  background: $primaryCardBGColor
  overflow: auto

@media (max-width: $tablet-max-height)
  .root
    padding: 20px

  .box
    min-height: calc(100vh - 40px)

  .header
    padding: 16px

  .body
    padding: 0
