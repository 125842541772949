@import 'src/theme/constants.scss'

.root
  display: flex
  gap: 10px
  width: 100%
  align-items: center
  padding: 11px 16px
  background: $itemSecondaryActiveBgColor
  border-radius: 6px
  border: 1px solid transparent
  line-height: 22px
  font-size: 1rem
  white-space: initial
  color: $mainText100Color
  user-select: none

.text
  flex: 1 1 100%

.root i
  font-size: 1.27em

.root i.arrow
  color: $itemPrimaryActiveColor
  font-size: 1em

.clickable
  cursor: pointer

.selected
  border-color: $itemSecondaryActiveColor

.disabled
  opacity: .7
  pointer-events: none
