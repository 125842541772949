.productList 
    min-height: 100%
    position: relative

.productListContainer
    padding-top: 1rem
    padding-bottom: 2.8125rem
    height: 100%
    min-height: 88vh

.alertText,
.notFoundBox
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.emptyContainer
    height: 100%
    justify-content: center
    align-items: center
    div
        text-align: center
        p
            text-align: center !important
        img 
            max-width: 50%
            margin-bottom: 0.5rem
            min-width: 30vw
.catName
    display: inline

.catContainer
    margin-bottom: 0.5rem

.productName
  cursor: pointer

.contextMenu
  display: flex
  width: 100%
  justify-content: flex-end

.actionColumn
  justify-content: right
