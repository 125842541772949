.root
  display: grid
  grid-template-rows: auto auto 1fr
  width: 100%
  align-items: flex-start

.header
  margin-bottom: 20px

.searchInput
  min-width: 300px

.headerButtons
  display: flex
  gap: 8px

