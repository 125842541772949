
@import 'src/theme/constants.scss'

.root
    width: 100%
    .error
        border: 1px solid $red100Color
    ul
        list-style-type: none
        padding-left: 20px
        margin: 0
        li
            position: relative
            &::before
                position: absolute
                content: '•'
                left: -15px
            &.done
                color: $green100Color
                &::before
                    content: '✓'
