@import 'src/theme/constants.scss'

.root
  display: grid
  grid-template-rows: auto 1fr
  width: 100%
  padding: 30px 60px
  width: 100%
  gap: 30px

.body
  display: grid
  height: max-content
  gap: 60px
  width: 100%
  grid-template-columns: 1fr 1fr
  grid-template-areas: "profile metrics"
  grid-auto-rows: auto

.profile
  display: grid
  flex-direction: column
  padding: 30px
  border-radius: 6px
  background: $primaryCardBGColor
  gap: 30px
  grid-area: 'prfile'

.statistic
  display: grid
  grid-template-columns: 1fr
  grid-auto-rows: auto
  height: min-content
  gap: 30px
  grid-area: 'metrics'

.footer
  display: flex
  justify-content: flex-end
  padding-top: 16px
  border-top: 1px solid $dividerLineLightColor

.control
  display: flex
  flex: 1 1 100%
  justify-content: space-between

@media (max-width: $tablet-min-width)
  .root
    padding: 0
    gap: 10px

  .body
    grid-template-columns: 1fr
    grid-template-areas: "profile" "metrics"
    gap: 20px

  .statistic
    gap: 20px

@media (max-width: $mobile-min-width)
  .profile
    padding: 20px
    gap: 20px
