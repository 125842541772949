@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.answerSection
  display: flex
  flex-direction: column
  width: 100%
  padding-left: 20px
  @include thinScroll
  &::-webkit-scrollbar
    width: 2px
  &::-webkit-scrollbar-thumb
    background-color: $primaryContrast10OpacityColor

.flex1
  flex: 1
  display: flex
  flex-direction: column
  gap: 16px
  margin-block: 24px
  width: 100%

.summary
  border: none
  border-radius: $small-border-radius
  display: flex
  flex-direction: column
  height: fit-content
  flex: 1

.summaryPassage
  background: $primaryContrast10OpacityColor
  padding: 24px
  border-radius: 8px

  div, p
    line-height: 28px

.summaryText

.loadingMessages
  display: flex
  flex-direction: column
  justify-content: flex-start
  width: 100%
  gap: 16px
  position: static
  top: 25vh
  left: 25%
  max-width: 550px
  margin-inline: auto
  margin-block: 100px 30px

.loading
  display: flex
  gap: 8px
  align-items: center

.neshIcon
  background: $primary100Color
  border-radius: 4px

.loader
  font-size: 14px
  height: 24px
  background: linear-gradient(90deg, $mainText100Color 0%, #B963FF 100%)
  background-clip: text
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  white-space: nowrap
  width: 500px
  text-overflow: ellipsis
  overflow: hidden

.info
  max-width: 500px

.avatarSummary
  display: flex
  gap: 16px

  & a, & a:visited
    color: $mainText100Color

  & mark
    background: transparent
    color: $mainText100Color

.fitContent
  width: fit-content
  flex: 0 0 auto

.action
  display: flex
  gap: 8px
  button
    width: max-content

.insights
  display: flex
  flex-direction: column
  gap: 12px

.rating
  display: flex
  gap: 8px
  align-items: center
  line-height: 30px

.placeholder
  line-height: 24px
  margin-bottom: 24px

.rightContainer
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: flex-end
  column-gap: 12px
  row-gap: 8px

@media (max-width: $tablet-min-height)
  .action
    width: 100%
    display: flex
  .control
    header
      flex-wrap: wrap
  .ratingWrapper
    justify-content: flex-start

  .loadingMessages
    position: static
    width: 100%
    height: auto

  .answerSection
    padding: 0px
    max-width: calc(100vw - 32px)

@media (max-width: $mobile-min-width)
  .action
    justify-content: space-between
    button
      flex: 1 1 auto
  .answerSection
    padding: 0px

@media (max-width: $tablet-max-height)
  .answerSection
    padding: 0px
    max-width: calc(100vw - 32px)

@media (min-width: $tablet-max-height) and (max-width: $tablet-max-width)
  .answerSection
    padding-right: 20px
