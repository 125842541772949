@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.root
  position: relative
  font-size: 1.4em
  height: min-content

  &:after
    content: attr(data-index)
    color: $primaryContrast100Color
    font-weight: 700
    position: absolute
    font-size: 0.5em
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  &.verified:after
    color: $contrastText100Color
